import {parse} from "partial-json";
import * as echarts from 'echarts';
import {Alpine, Livewire} from '../../vendor/livewire/livewire/dist/livewire.esm';
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect);

window.parsePartialJSON = parse;
window.echarts = echarts;
window.lastAnalysis = null;

Alpine.bind('FadeIn', () => ({
    ['x-data']: '{show: false}',
    ['x-init']: '$nextTick(() => { show = true })',
    ['x-transition.opacity.scale.duration.500ms']() {
        return true;
    },
    ['x-show']() {
        return this.show;
    }
}));

Alpine.data('Toggleable', (expanded = false) => {
    return {
        expanded,
        toggle() {
            this.expanded = !this.expanded;
        }
    };
});

// Create
Alpine.data('CreateForm', function () {
    return {
        currentIndex: 0,
        currentExample: window.promptExamples[0],
        examples: window.promptExamples,
        timer: null,
        focusTimer: null,
        value: '',
        hasFocusWithin: false,
        get noPrompt() {
            return this.value.trim() === '';
        },
        focusIn() {
            clearTimeout(this.focusTimer);
            this.hasFocusWithin = true;
        },
        focusOut() {
            this.focusTimer = setTimeout(() => this.hasFocusWithin = false, 300);
        },
        setPrompt(prompt) {
            Livewire.getByName('create-form')[0].prompt = prompt;
            this.value = prompt.trim();
        },
        init() {
            this.timer = setInterval(
                () => {
                    if (!this.examples[this.currentIndex + 1]) {
                        this.currentIndex = 0;
                    } else {
                        this.currentIndex++;
                    }
                },
                5000
            );
        },
        destroy() {
            clearInterval(this.timer);
        }
    }
});

// Questions
Alpine.data('FormPreview', (formModel) => {
    return {};
});

// FormRevision
Alpine.data('FormRevisions', (revisions) => {
    const obj = {
        revisions: Alpine.reactive(revisions),
    };

    window.formRevisions = obj;
    return obj;
});

Livewire.hook('stream', function (stream) {
    if (stream.name === 'revisions') {
        const partialJSON = parsePartialJSON(stream.content) || [];
        window.formRevisions.revisions.splice(0, window.formRevisions.revisions.length, ...partialJSON);
        console.log(partialJSON);
    }
});

Livewire.hook('stream', function (stream) {
    if (stream.name === 'form') {
        let partialJSON = parsePartialJSON(stream.content) || [];
        if (!Array.isArray(partialJSON)) {
            partialJSON = [];
        }

        Alpine.effect(() => {
            window.formRevisions.revisions[window.formRevisions.revisions.length - 1].model = partialJSON;
        });

        console.log(window.formRevisions.revisions[window.formRevisions.revisions.length - 1]);
    }
});


// FormRevision
Alpine.data('FormResponsesAnalyses', (analyses) => {
    const obj = {
        analyses: Alpine.reactive(analyses),
    };

    window.formResponsesAnalyses = obj;
    return obj;
});

Livewire.hook('stream', function (stream) {
    if (stream.name === 'analyses') {
        const partialJSON = parsePartialJSON(stream.content) || [];
        window.formResponsesAnalyses.analyses.splice(0, window.formResponsesAnalyses.analyses.length, ...partialJSON);
        console.log(partialJSON);
    }
});

// Responses Analyses
Alpine.data('PieChart', ({chartData}) => {
    return {
        chartInstance: null,
        initChart() {
            this.$nextTick(() => {
                if (!this.chartInstance) {
                    this.chartInstance = echarts.init(this.$el, null, {renderer: 'svg'});
                    this.chartInstance.setOption({
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            bottom: '5%',
                            left: 'center'
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: ['40%', '60%'],
                                avoidLabelOverlap: true,
                                padAngle: 3,
                                itemStyle: {
                                    borderRadius: 4
                                },
                                labelLine: {
                                    show: true
                                },
                                data: chartData
                            }
                        ]
                    });
                    setTimeout(() => this.chartInstance.resize(), 200);
                } else {
                    this.chartInstance.resize();
                }
            });
        },
        init() {

        }
    };
});

Alpine.data('FormResponsesAnalysis', (analysisModel) => {
    return {}
});

Livewire.hook('stream', function (stream) {
    if (stream.name === 'analysis') {
        let partialJSON = parsePartialJSON(stream.content) || [];
        if (!Array.isArray(partialJSON)) {
            partialJSON = [];
        }

        Alpine.effect(() => {
            window.formResponsesAnalyses.analyses[window.formResponsesAnalyses.analyses.length - 1].items = partialJSON;
        });

        console.log(window.formResponsesAnalyses.analyses[window.formResponsesAnalyses.analyses.length - 1]);
    }
});

Alpine.data('ScrollToLastItem', (isLast) => (
        {
            init() {
                if (isLast) {
                    setTimeout(
                        () => {
                            if (this.$el) {
                                this.$el.closest('.scroll-ref').scrollTo({
                                    top: this.$el.offsetTop - 140,
                                    behavior: 'smooth'
                                });
                            }
                        },
                        500
                    );
                }
            }
        }
    )
);

Alpine.data('ScrollHere', (once = false) => {
    return {
        interval: null,
        init() {
            this.interval = setInterval(
                () => {
                    const chat = this.$el.closest('.scroll-ref');
                    if (!chat) {
                        this.destroy();
                        return;
                    }

                    if (once) {
                        this.destroy();
                    }

                    if (this.$el.getBoundingClientRect().top <= document.documentElement.clientHeight) {
                        chat.scrollTo({
                            top: this.$el.offsetTop,
                            behavior: 'smooth'
                        });
                    }
                },
                500
            )
        },
        destroy() {
            clearInterval(this.interval);
            // window.removeEventListener('scroll', this.scrollWatcher);
        }
    };
});


Livewire.start();
